import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

import { Link } from 'react-router-dom';
// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";
import Table from "../../dashboard/components/Table/Table";

// data
import mock from "../../dashboard/mock";
import firebase from "firebase";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

const db = firebase.firestore();

class IngredientsList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new Menu',
    description: 'About description',
  };

  constructor() {
    super();
    this.state = { dataSet: [] };
  }
  componentDidMount(){
    db.collection('menus').where('resturent_id', '==', localStorage.getItem("uidr"))
            .get()
            .then( snapshot => {
                const dataSet = []
                snapshot.forEach(doc => {
                    dataSet.push([doc.data().dish_name,<img src={doc.data().featured_image} style={{ width: 70,height: 70, borderRadius: '50%' }}/>,'€'+doc.data().price,doc.id,doc.id]);
                });
                  return dataSet;
              })
             .then(userList => { 
              this.setState({dataSet:userList});
            })
        .catch(error => console.log(error))
  }
  menudelete(id) {
      if (window.confirm("Delete the item?")) {
      db.collection("menus").doc(id).delete();
      this.componentDidMount();
    }
    }


  render() {
  return (
    <>
      <PageTitle title="Menus List" />
      <div className="pull-right mt-n-xs">
                <Link to="/app/Menus/new" className="btn btn-primary btn-inverse">
                  Create New Menu
                </Link>
              </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title=""
            data={this.state.dataSet}
            columns={["Dish Name","Image","Price", {
              label: "Edit",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                          <Link to={"/app/Menus/edit/" + value} className='btn btn-primary'> Edit </Link>
                      )
                  }
              }
          },
          {
            label: "Delete",
            options: {
                customBodyRender: (value, tableMeta, menudelete) => {
                    return (
                        <a className='btn btn-primary' onClick={() => this.menudelete(value)}> Delete </a>
                    )
                }
            }
        }
        ]}
            options={{
              filterType: "checkbox",
            }}
          />
        </Grid>
        {/*<Grid item xs={12}>
          <Widget title="Material-UI Table" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <Table data={mock.table} />
          </Widget>
        </Grid>*/}
      </Grid>
    </>
  );
}
}
export default withRouter(IngredientsList);



