import firebase from "firebase";
export default async function Addmenudata(object) {
  try{
    const userRef = await firebase.firestore().collection('menus').add({
      dish_name: object.name,
      price:object.price,
      resturent_id:localStorage.getItem('uidr'),
      resturent_name:localStorage.getItem('namer'),
      featured_image:object.image,
      description:object.description,
      category:object.category,
      video:object.videolink,
      related:object.related,
      variation:object.variation,
      type:object.type,
      ratting:0,
      recommended:"false",
      status:"active",
      Ingredient:object.ing
    });
   var data = await userRef.docs.map(doc => doc.data());
    return data;
  }
  catch(e){
    return "error"
  }
}