import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
//import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
//import logo from "./logo.svg";
//import google from "../../images/google.svg";

// context
import firebase from "firebase";
import { useUserDispatch, loginUser } from "../../context/UserContext";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  //var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [ptype, pset] = useState("password");
  var [icon, iset] = useState("fa fa-eye");
  var [model, setmodel] = useState("login");
  function doSomething(){
    let vals=''
    if(model=='login'){
      vals='forgot';
    }else{
      vals='login';
    }
    setmodel(vals)
  }
  function sendEmail(){

    var email=loginValue;
    var actionCodeSettings = {
      url: 'https://vendorapp-aa075.web.app?home=true',
      handleCodeInApp: false
    };
    firebase.auth().sendPasswordResetEmail(loginValue,actionCodeSettings).then((res) => {
   alert('Forget Password Email Send Successfully On '+email);
    }).catch((error) => {
  console.log('err',error);
    });
  }

  function doWatch(){
    if(ptype=='password'){
      pset('text');
      iset('fa fa-eye-slash');
    }else{
      pset('password');
      iset('fa fa-eye');
    }
    
  }
  return (
    <Grid container className={classes.container}>
     
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
           <img class="logoadmin" src="./images/uc.png"/>
          </Tabs>

          {model=='login' ? <>{activeTabId === 0 && (
            <React.Fragment>
             
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              <div class="formGroup">
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type={ptype}
                fullWidth
                
              />
              <i class={icon} onClick={doWatch} aria-hidden="true"></i></div>
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError,
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                )}
              {/* <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                >
                  Forget Password
                </Button>*/}
               <p onClick={doSomething}>Forget Password</p>
              </div>
            </React.Fragment>
          )}</>:            <React.Fragment>
             
          <TextField
            id="email"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={loginValue}
            onChange={e => setLoginValue(e.target.value)}
            margin="normal"
            placeholder="Email Adress"
            type="email"
            fullWidth
          />
          <div className={classes.formButtons}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
              <Button
                onClick={sendEmail}
                variant="contained"
                color="primary"
                size="large"
              >
                Submit
              </Button>
            )}
          {/* <Button
              color="primary"
              size="large"
              className={classes.forgetButton}
            >
              Forget Password
            </Button>*/}
           <p onClick={doSomething}>Go to login</p>
          </div>
        </React.Fragment>}

          
        </div>
        <Typography color="primary" className={classes.copyright}>
        © {new Date().getFullYear()} All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
