import firebase from "firebase";
export default async function getcategory () {
          var response =  await firebase.firestore().collection("category").get();
            const data = await response.docs.map(doc => doc.data());
               if(data!='')
               {
                   return await data;
               }
               else
               {
                   return "error"
               }
                           
}