import React from "react";
import firebase from "firebase";
import firestore from "../firestore";
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

async function loginUser (dispatch, login, password, history, setIsLoading, setError){
  setError(false);
  setIsLoading(true);
  try{
    const authdb = firebase.auth();
    var login = await authdb.signInWithEmailAndPassword(login, password);
    var response2 =  await firebase.firestore().collection("restaurants").where('uid', '==', login.user.uid).get();
    const data = await response2.docs.map(doc => doc.data());
    localStorage.setItem('phoner',data[0].phone_no);
    localStorage.setItem('uidr', login.user.uid);
    localStorage.setItem('namer',data[0].name);
    localStorage.setItem('emailr',data[0].restaurant_email);
    if( data[0].restaurant_email && data[0].restaurant_email!=='')
    {
    setTimeout(() => {
    localStorage.setItem('id_token', 1)
    setError(null)
    setIsLoading(false)
    dispatch({ type: 'LOGIN_SUCCESS' })
    history.push('/app/dashboard')
    }, 2000);
    }
    else
    {
    setIsLoading(false);
    alert('email password mismatch');
    }
    }
    catch(e){
    setIsLoading(false);
    alert('email password mismatch');
    }
    }
//   const db = firebase.firestore();

// db.collection("superadmin")
//       .where('email', '==', login)
//       .where('password', '==', password)
//       .get()
//       .then(querySnapshot => {
//          const data = querySnapshot.docs.map(doc => doc.data());
//          if(data!=='')
//          {
//           setTimeout(() => {
//             localStorage.setItem('id_token', 1)
//             setError(null)
//             setIsLoading(false)
//             dispatch({ type: 'LOGIN_SUCCESS' })
//             history.push('/app/dashboard')
//           }, 2000);
//          }
//          else
//          {
          
//     setIsLoading(false);
//      alert('email password mismatch');

//          }
          // console.log(querySnapshot.docs[0].data());
//     });
// }

function signOut(dispatch, history) {
  localStorage.clear(); 
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
