import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,

  Area,
  // PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";
import { PieChart } from 'react-minimal-pie-chart';

// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import firebase from "firebase";
import moment from "moment";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import { Line } from "react-chartjs-2";
// const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

// export default function Dashboard(props) {

  // var classes = useStyles();
  // var theme = useTheme();

 
  const db = firebase.firestore();

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {totalcomm:0,totalco:0,
      name: '',totalview:0,totalrestu:0,totalorder:0,alltotal:0,columns:[
        { title: 'Email', field: 'email' },
        { title: 'Phone Number', field: 'phone'},
        { title: 'Date', field: 'date' },
        { title: 'Country', field: 'country' },
        { title: 'City', field: 'city' },
        { title: 'Total Amount', field: 'total' },
        { title: 'Commition', field: 'commition' },
        { title: 'Accept', field: 'accept' },
        { title: 'Reject', field: 'reject' },
      
      ],dataSet:[],totalToday:0,totalWeb:0,totalApp:0,totaltwo:0,rate:0,totalrat:0,totalrev:0,counter:0,data10:[]
    };
  }
  componentDidMount=async()=>
  {
    var elx = new Date();
    var datq= moment(elx).format('DD/MM/YYYY');
    var datqt= moment(elx).format('YYYY');
    var dr= moment(elx).format('MM');

    var response4 = await db.collection("orders").where('approve', '==', 'accept').where('restu_id', '==', localStorage.getItem("uidr")).get();
    const data4 = await response4.docs.map(doc => doc.data());
    console.log('dasasdfasd',data4);
    var response66 = await db.collection("orders").where('restu_id', '==', localStorage.getItem("uidr")).get();
    const data44 = await response66.docs.map(doc => doc.data());
    const data10 = await response66.docs.map(doc => doc.id);
    var response2 = await db.collection("menus").where('resturent_id', '==', localStorage.getItem("uidr")).get();
    const data2 = await response2.docs.map(doc => doc.data());
    var response6 = await db.collection("ingredients").where('uid', '==', localStorage.getItem("uidr")).get();
    const data6 = await response6.docs.map(doc => doc.data());
    var response7 = await db.collection("reviews").where('restu_id', '==', localStorage.getItem("uidr")).get();
    const data7 = await response7.docs.map(doc => doc.data());
    this.setState({totalorder:data4.length,data10:data10,totalrat:data6.length,totalrev:data7.length,totalm:data2.length});
    var total=0;
    var title=[];
    var jan=0;
    var feb=0;
    var march=0;
    var april=0;
    var may=0;
    var jun=0;
    var july=0;
    var agust=0;
    var sept=0;
    var oct=0;
    var nov=0;
    var dec=0;
    var totaltwo=0;
    var totalcomm=0;
    var totalco=0;
    var counter=0;
   
   for (let index = 0; index < data4.length; index++) {
     if(data4[index].approve=="accept")
     {
    total+=data4[index].grandtotal;
    totalco+=data4[index].commition;
     }
    if(datqt==moment(new Date(data4[index].current_date)).format('YYYY'))
    {
      if("01"==moment(new Date(data4[index].current_date)).format('MM'))
      {
        jan+=data4[index].grandtotal;
      }
      if("02"==moment(new Date(data4[index].current_date)).format('MM'))
      {
        feb+=data4[index].grandtotal;
      }
      if("03"==moment(new Date(data4[index].current_date)).format('MM'))
      {
        march+=data4[index].grandtotal;
      }
      if("04"==moment(new Date(data4[index].current_date)).format('MM'))
      {
        april+=data4[index].grandtotal;
      }
      if("05"==moment(new Date(data4[index].current_date)).format('MM'))
      {
        may+=data4[index].grandtotal;
      }
      if("06"==moment(new Date(data4[index].current_date)).format('MM'))
      {
        jun+=data4[index].grandtotal;
      }
      if("07"==moment(new Date(data4[index].current_date)).format('MM'))
      {
        july+=data4[index].grandtotal;
      }
      if("08"==moment(new Date(data4[index].current_date)).format('MM'))
      {
        agust+=data4[index].grandtotal;
      }
      if("09"==moment(new Date(data4[index].current_date)).format('MM'))
      {
        sept+=data4[index].grandtotal;
      }
      if("10"==moment(new Date(data4[index].current_date)).format('MM'))
      {
        oct+=data4[index].grandtotal;
      }
      if("11"==moment(new Date(data4[index].current_date)).format('MM'))
      {
        nov+=data4[index].grandtotal;
      }
      if("12"==moment(new Date(data4[index].current_date)).format('MM'))
      {
        dec+=data4[index].grandtotal;
      }
    }
     
   }

   for (let index = 0; index < data44.length; index++) {
    // if(datq==moment(new Date(data44[index].current_date)).format('DD/MM/YYYY'))
    // {
    if(moment(new Date()).format('DD-MM-YY') == moment(new Date(data44[index].current_date)).format('DD-MM-YY'))
    {
          title.push({
            key:data10[index],
          email:data44[index].email,
          phone: data44[index].phone,
          date:moment(new Date(data44[index].current_date)).format('DD/MM/YYYY'),
          country: data44[index].country,
          city:data44[index].country,
          total:Math.round(data44[index].grandtotal),
          commition:Number(data44[index].commition),
          accept:data44[index].approve!=="reject" ? <button type="button" class="btn btn-success" onClick={data44[index].approve=="pending" ? this.accept.bind(this): this.emty.bind(this)} data-div_name={data10[index]} data-div_index={index} >{data44[index].approve=="pending" ? <span>Accept</span>:data44[index].approve == "accept" ? <span>Accepted</span>:""}</button>:"",
          reject:data44[index].approve!=="accept" ? <button type="button" class="btn btn-danger"  onClick={data44[index].approve=="pending" ? this.reject.bind(this): this.emty.bind(this)} data-div_name={data10[index]} data-div_index={index} >{data44[index].approve=="pending" ? <span>Reject</span>:data44[index].approve == "reject" ? <span>Rejected</span>:""}</button>:""
    });
    counter++;
  }
    if(data44[index].approve=="accept" && moment(new Date()).format('DD-MM-YY') == moment(new Date(data44[index].current_date)).format('DD-MM-YY') )
    {
          totaltwo+=data44[index].grandtotal;
          totalcomm+=data44[index].commition;
          
    }
        // }
    
  }
  
   this.setState({counter:counter,totaltwo:totaltwo,totalcomm:totalcomm,alltotal:total,totalco:totalco,dataSet:title,jan:jan,feb:feb,march:march,april:april,may:may,jun:jun,july:july,agust,agust,sept:sept,oct:oct,nov:nov,dec:dec});
  //  var totalr=[];
  //  for (let index = 0; index < data5.length; index++) {
  //   if(datq == moment(new Date(data5[index].current_date)).format('DD/MM/YYYY'))
  //   {
  //     totalr.push(data5[index]);
  //   }
  //  }
  //  this.setState({totalToday:totalr.length});
   var element=0;
   for (let index = 0; index < data6.length; index++) {
      element+=data6[index].ratting;
   }
   if(element!==0)
   {
   this.setState({rate:element/data6.length});
  }
  }

  accept= async(e)=>
    {
    var id =  e.currentTarget.dataset.div_name;
    var index=e.currentTarget.dataset.div_index;
    var status=e.currentTarget.id;
    var objectIndex=this.state.dataSet.findIndex((obj => obj.key == id));
    if(objectIndex!==-1)
    {
      this.state.dataSet[objectIndex].approve="accept";
      this.setState({dataSet:this.state.dataSet});
      var data = await db.collection('orders').doc(id).update({approve:"accept"});
      this.componentDidMount();
    }
    
  }
    reject= async(e)=>
    {
      var id =  e.currentTarget.dataset.div_name;
      var index=e.currentTarget.dataset.div_index;
      var status=e.currentTarget.id;
      var objectIndex=this.state.dataSet.findIndex((obj => obj.key == id));
      if(objectIndex!==-1)
      {
        this.state.dataSet[objectIndex].approve="reject";
        this.setState({dataSet:this.state.dataSet});
        var data = await db.collection('orders').doc(id).update({approve:"reject"});
        this.componentDidMount();
      }
    }
    emty()
    {
      
    }
render(){
  // local
  // var [mainChartState, setMainChartState] = useState("monthly");

  return (
    <>
      {/* <PageTitle title="Dashboard" button={<Button
      variant="contained"
      size="medium"
      color="secondary"
    >
        Latest Reports
    </Button>} /> */}
    
      <Grid container spacing={4} >
        
        <Grid item lg={3} md={6} sm={6} xs={12} className="topclass">
          <Widget
            title="Today Earning"
            upperTitle
          >
            <div>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
               {this.state.totaltwo-this.state.totalcomm}
              </Typography>
                </Grid>
              </Grid>
            </div>
         <div className="totalrestu"><img src="/images/doller.png"/></div>
                {/*<Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                 Today Earning
                </Typography>
                <Typography size="md">{'€'+Math.round(this.state.totaltwo)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="text" colorBrightness="secondary" noWrap>
               Restaurant Earning
                </Typography>
                <Typography size="md">{'€'+Math.round(this.state.totaltwo-this.state.totalcomm)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="text" colorBrightness="secondary" noWrap>
               Admin Earning
                </Typography>
                <Typography size="md">{'€'+Math.round(this.state.totalcomm)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                   Today Rate
                </Typography>
                <Typography size="md">{Math.round(this.state.rate)+'%'}</Typography>
              </Grid>
            </Grid> */}
          </Widget>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12} className="topclass">
          <Widget
            title="Today Orders"
            upperTitle
          >
                <div>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
                 <Typography size="xl" weight="medium" noWrap>
              {this.state.counter}
              </Typography>
                </Grid>
              </Grid>
            </div>
            <div className="totalrestu"><img src="/images/cart.png"/></div>
          </Widget>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12} className="topclass">
          <Widget
            title="Total Eerning"
            upperTitle
          >
            <div>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
              {this.state.alltotal-this.state.totalco}
              </Typography>
                </Grid>
              </Grid>
            </div>
            <div className="totalrestu"><img src="/images/doller.png"/></div>
          </Widget>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12} className="topclass">
          <Widget
            title="Total Orders"
            upperTitle
          >
            <div>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
              {this.state.totalorder}
              </Typography>
                </Grid>
              </Grid>
            </div>
            <div className="totalrestu"><img src="/images/cart.png"/></div>
          </Widget>
        </Grid>
       
        <Grid item xs={12}>
          <Widget
            // bodyClass={classes.mainChartBody}
            header={
              <div >
                <Typography
                  variant="h5"
                  color="text"
                  colorBrightness="secondary"
                >
               Earning Chart
                </Typography>
              
              </div>
            }
          >
            <ResponsiveContainer width="100%" minWidth={500} height={350}>
            <Line data={{labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","July","Aug","sep","Oct","Nov","Dec"],
  datasets: [
    {
      label: "Web",
      data: [this.state.jan,this.state.feb,this.state.march,this.state.april,this.state.may,this.state.jun,this.state.july,this.state.agust,this.state.sept,this.state.oct,this.state.nov,this.state.dec],
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)"
    },
    {
      label: "App",
      data: [0,0,0,0,0,0,0,0,0,0,0,0],
      fill: false,
      borderColor: "#742774"
    }
  ]
}} />
           </ResponsiveContainer>
          </Widget>
        </Grid>
      

          <Grid item md={4} sm={6} xs={12} className="bottomclass"  >
          <Widget
            title="Total Ingredients"
            upperTitle
          >
            <div>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
              {this.state.totalrat}
              </Typography>
                </Grid>
              </Grid>
            </div>
            <div className="resgisteruser"><img src="/images/ings.png"/></div>
          </Widget>
          </Grid>
          <Grid item md={4} sm={6} xs={12} className="bottomclass" >
          <Widget
            title="Total Menus"
            upperTitle
          >
            <div>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
              {this.state.totalm}
              </Typography>
                </Grid>
              </Grid>
            </div>
            <div className="resgisteruser"><img src="/images/restu.png"/></div>
          </Widget>
          </Grid>
          <Grid item md={4} sm={6} xs={12} className="bottomclass">
          <Widget
            title="Total Reviews"
            upperTitle
          >
            <div>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
              {this.state.totalrev}
              </Typography>
                </Grid>
              </Grid>
            </div>
            <div className="resgisteruser"><img src="/images/rev.png"/></div>
          </Widget>
          </Grid>
    
        <Grid item xs={12}>
          <Widget
            title="Today Orders"
            upperTitle
            noBodyPadding
            // bodyClass={classes.tableWidget}
          >{this.state.dataSet.length>0 ?
            <MaterialTable
            title=""
            data={this.state.dataSet}
            columns={this.state.columns}
          />:""}
          </Widget>
        </Grid>
        
      </Grid>

    </>
  );
}

// #######################################################################
// function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
//   var array = new Array(length).fill();
//   let lastValue;

//   return array.map((item, index) => {
//     let randomValue = Math.floor(Math.random() * multiplier + 1);

//     while (
//       randomValue <= min ||
//       randomValue >= max ||
//       (lastValue && randomValue - lastValue > maxDiff)
//     ) {
//       randomValue = Math.floor(Math.random() * multiplier + 1);
//     }

//     lastValue = randomValue;

//     return { value: randomValue };
//   });
// }

// function getMainChartData() {
//   var resultArray = [];
//   var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
//   var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
//   var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

//   for (let i = 0; i < tablet.length; i++) {
//     resultArray.push({
//       tablet: tablet[i].value,
//       desktop: desktop[i].value,
//       mobile: mobile[i].value,
//     });
//   }

//   return resultArray;
// }


}
export default withRouter(Dashboard);
