import React from 'react';
import { Switch, Route, withRouter } from 'react-router';

import MyprofileList from './list/MyprofileList';

class Myprofile extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/Myprofile" exact component={MyprofileList} />
      </Switch>
    );
  }
}

export default withRouter(Myprofile);
