import firebase from "firebase";
const config = {
    apiKey: "AIzaSyAWGSCJlsXL69KW_Ml1PCUT-zOKH4mII-c",
    authDomain: "vendorapp-aa075.firebaseapp.com",
    projectId: "vendorapp-aa075",
    storageBucket: "vendorapp-aa075.appspot.com",
    messagingSenderId: "656898873702",
    appId: "1:656898873702:web:93ac32ccc90a0704a950a7",
};
firebase.initializeApp(config);
export default firebase;
