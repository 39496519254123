import React from 'react';
import { Switch, Route, withRouter } from 'react-router';

import MenusList from './list/MenusList';
import MenusNew from './new/MenusNew';
import MenusEdit from './edit/MenusEdit';

class Menus extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/Menus" exact component={MenusList} />
        <Route path="/app/Menus/new" exact component={MenusNew} />
        <Route path="/app/Menus/edit/:id" exact component={MenusEdit} />
      </Switch>
    );
  }
}

export default withRouter(Menus);
