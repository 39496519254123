import React from 'react';
import { Switch, Route, withRouter } from 'react-router';

import ReviewsList from './list/ReviewsList';

class Reviews extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/Reviews" exact component={ReviewsList} />
      </Switch>
    );
  }
}

export default withRouter(Reviews);
