import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget/Widget';
import { Multiselect } from 'multiselect-react-dropdown';
import getIngredient from '../API/getIngredient.js';
import getcategory from '../API/getcategory.js';
import getMenus from '../API/getMenus.js';
import Addmenudata from '../API/Addmenudata.js';
import { withRouter } from "react-router-dom";
import axios from "axios";
import firebase from "firebase";
import Select from 'react-select';
const db = firebase.firestore();
const options = [
  { value: 'Single', label: 'Single' },
  { value: 'Variation', label: 'Variation' },
];
const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
class IngredientsNew extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new Menu',
    description: 'About description',
  };

  constructor(props) {
    super(props);

    this.state={ingredient:[],menus:[],category:[],getIdeal:[],Alogo:[],selectedAlo:[],selectedmenu:[],selectedCat:[],selectedIng:[],
      imageVideoUrl:"",postType:"",name:"",price:"",des:"",con:"",rin:"",peso:"",selectedValue:"",downloadURL:"",image:"",videolink:"",loading:false,
      inputList: [{ size: "", price: "" }],selectedOption:null,type:'',
    };
   
  }

  componentDidMount= async ()=>{
    try 
    {
       const ingredient = await getIngredient();
       this.setState({ingredient:ingredient.data});
    }
    catch(e)
    {
        console.log('first else');
    }
    try 
    {
      const category = await getcategory();
      this.setState({category:category});
    
    }
    catch(e)
    {
        console.log('first else');
    }
  
   

    try 
    {
       const ingredient = await getMenus();

       if(ingredient.list.length>0)
       {
       var title=[]
       for (var i = 0; i < ingredient.list.length; i++) {
       title[i] = {
       name:ingredient.list[i].dish_name,
       key:ingredient.ids[i]
       };
       }
       this.setState({menus:title});
       }


    }
    catch(e)
    {
        console.log('first else');
    }
  }

   handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.inputList];
    if(name=='price'){
    if (rx_live.test(value)){
      list[index][name] = value;
      this.setState({inputList:list});
      }
    }else{
      list[index][name] = value;
      this.setState({inputList:list});
    }
  };

  // handle click event of the Remove button
   handleRemoveClick = index => {
    const list = [...this.state.inputList];
    list.splice(index, 1);
    this.setState({inputList:list});
  };

  // handle click event of the Add button
   handleAddClick = () => {

    this.setState(prevState => ({ 
      inputList: [...prevState.inputList, { size: "", price: "" }]
    }),()=>{
      console.log('inc',this.state.inputList);
    })
    // this.setState({inputList:[...this.state.inputList, { firstName: "", lastName: "" }]});
  };


onSelectmenu(selectedList, selectedItem) {
  this.setState({selectedmenu:selectedList});
}
onRemovemenu(selectedList, removedItem) {
this.setState({selectedmenu:selectedList});
}



onSelectCat(selectedList, selectedItem) {
    this.setState({selectedCat:selectedList});
}
onRemoveCat(selectedList, removedItem) {
  this.setState({selectedCat:selectedList});
}
onSelectIng(selectedList, selectedItem) {
  var output=[];
  selectedList.forEach(function(value, key) {
      output.push(value.name); 
  });
  this.setState({selectedIng:output});
}
onRemoveIng(selectedList, removedItem) {
var output=[];
  selectedList.forEach(function(value, key) {
      output.push(value.name); 
  });
  this.setState({selectedIng:output});
}

getname(e)
{
this.setState({name:e.target.value});
}
getprice(e)
{
  this.setState({price:e.target.value});
}

fetchdes(e)
{
  this.setState({des:e.target.value});
}

onImageVideoChange = e => {
  let file = e.target.files[0];  
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
  var streetaddress= reader.result.substr(0, reader.result.indexOf('/'));
  if(streetaddress=="data:image")
  {
    this.setState({  
      imageVideoUrl: reader.result
   });
  }
};}


submitdata= async ()=>{
if(this.state.name=="" || this.state.type=="" || this.state.des=="" || this.state.selectedCat==[] || this.state.selectedIng==[] || this.state.imageVideoUrl=="")
{
alert('please fill all details');
}
else{
this.setState({loading:true});
var body={image:this.state.imageVideoUrl};
axios.post('https://rapidmalta.com/APIS/admin/imageurltwo',body, {
  headers: {
  Accept: "application/json",
  "Content-Type": "application/json",
},
}).then((res) => {
this.setState({downloadURL:res.data.image},()=>
{
if(this.state.downloadURL=="")
{
alert('please Select image');
this.setState({loading:false});
}
else{
this.storedata();
}
});
})
}
}

getType=(selectedOption)=>{
  this.setState({ selectedOption });
  this.setState({type:selectedOption.value})
}



storedata=async()=>
{
  // var vals=this.state.inputList;
  // for (let index = 0; index < vals.length; index++) {
  //   if(vals[index].size=='' || vals[index].price){
  //   alert('please fill variation form');
  //   return;
  //   }
    
  // }
var object={
'name':this.state.name,
'price':this.state.type=='Single' ? this.state.price : this.state.inputList[0].price,
'image':this.state.downloadURL,
'description':this.state.des,
'related':this.state.selectedmenu,
'category':this.state.selectedCat,
'ing':this.state.selectedIng,
'videolink':this.state.videolink,
'variation':this.state.inputList,
'type':this.state.selectedOption.value
}
try{
  var datasubmited = await Addmenudata(object);
  if(datasubmited)
  {
    alert("New dish Added successfully");
    this.setState({loading:false});
    this.props.history.push('/app/Menus')
  }
}
catch(e)
{

}
}
videolinkfunction(e)
{
this.setState({videolink:e.target.value});
}
  render() {
    return (
      <div>
        <h1>Create New Menus</h1>

        <Row>
                  <Col className="pl-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Select Categories
                        </label>
                        <Multiselect
                          options={this.state.category} // Options to display in the dropdown
                          selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                          onSelect={this.onSelectCat.bind(this)} // Function will trigger on select event
                          onRemove={this.onRemoveCat.bind(this)} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                          />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Dish Name</label>
                        <Input
                         id="input-name"
                          onChange={this.getname.bind(this)}
                          placeholder="Dishname"
                          type="text"
                          value={this.state.title}
                          required
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="px-1" md="6">
                      <FormGroup>
                        <label>Dish types</label>
                      <Select
                      value={this.state.selectedOption}
                      onChange={this.getType}
                      options={options}
                      />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Select Related Dish</label>
                        <Multiselect
                          options={this.state.menus} // Options to display in the dropdown
                          selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                          onSelect={this.onSelectmenu.bind(this)} // Function will trigger on select event
                          onRemove={this.onRemovemenu.bind(this)} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                          />
                      </FormGroup>
                    </Col>


                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Select Ingredient</label>
                        <Multiselect
                            options={this.state.ingredient} // Options to display in the dropdown
                            selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                            onSelect={this.onSelectIng.bind(this)} // Function will trigger on select event
                            onRemove={this.onRemoveIng.bind(this)} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Tutorial link</label>
                        <Input
                          placeholder="Tutorial link"
                          type="text"
                          onChange={this.videolinkfunction.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>Description</label>
                        <Input
                          cols="80"
                          placeholder="Description"
                          rows="4"
                          as="textarea"
                          onChange={this.fetchdes.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>Dish Image</label>
                        <input
                            type="file" class="form-control" id="file" onChange={this.onImageVideoChange.bind(this)}  name="myfile"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  
                  <Row>
              
                    {this.state.imageVideoUrl!="" ? <Col md="6">
        
              <div className="">
                <img
                  alt="..."
                  src={this.state.imageVideoUrl}
                ></img>
              </div>
          </Col>:""}
                  </Row>
                  {this.state.type=='Single' ? <Row>
                  <Col className="px-1" md="6">
                      <FormGroup>
                        <label>Price</label>
                        <Input
                          placeholder="Price"
                          type="Number"
                          onChange={this.getprice.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>:<>
                  
                  {this.state.inputList.map((x, i) => {
        return (
          <Row className="mainrow">
          <Col className="px-1" md="5">
            <input
              name="size"
              class="form-control"
              placeholder="Enter Size"
              value={x.size}
              onChange={e => this.handleInputChange(e, i)}
            />
            </Col>
            <Col className="px-1" md="5">
            <input
              class="form-control"
              name="price"
              placeholder="Enter Price"
              value={x.price}
              onChange={e => this.handleInputChange(e, i)}
            />
            </Col>
            <Col className="px-1" md="2">
            <div className="btn-box">
              {this.state.inputList.length !== 1 && 
              <button
                className="btn removex"
                onClick={this.handleRemoveClick.bind(this,i)}>Remove</button>}
              {this.state.inputList.length - 1 === i && <button className="btn addx" onClick={this.handleAddClick.bind(this)}>Add</button>}
            </div>
            </Col>
          </Row>
        );
      })}
          
          </>}
                  <Row>
                  <Button
                    className="btn-fill pull-right"
                    type="button"
                    variant="info"
                    onClick={this.state.loading==false ? this.submitdata.bind(this) : ""}
                  >
                   {this.state.loading==true ? "Creating....." : "Add Menue"}
                  </Button>
                  <div className="clearfix"></div>
              
            
         
          
        </Row>
        {/* <Row>
          <Col sm={6}>
              <Form onSubmit={this.doCreatePost}>
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="info">
                    {this.props.message}
                  </Alert>
                )}
                <FormGroup>
                  <Label for="input-title">Name</Label>
                  <Input
                    id="input-title"
                    type="text"
                    placeholder="Title"
                    value={this.state.title}
                    required
                    onChange={this.changetitle.bind(this)}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="danger" type="submit">
                      {this.props.isFetching ? 'Creating...' : 'Create'}
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
          </Col>



          
        </Row> */}
      </div>
    );
  }
}



export default withRouter(IngredientsNew);
