import React from 'react';
import { Switch, Route, withRouter } from 'react-router';

import RattingList from './list/RattingList';
class Ratting extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/Ratting" exact component={RattingList} />
      </Switch>
    );
  }
}

export default withRouter(Ratting);
