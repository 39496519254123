import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget/Widget';

import { withRouter } from "react-router-dom";
import firebase from "firebase";
import { Multiselect } from 'multiselect-react-dropdown';
import getIngredient from '../API/getIngredient.js';
import getcategory from '../API/getcategory.js';
import getMenus from '../API/getMenus.js';
import Updatemenudata from '../API/Updatemenudata.js';
import fetchmenu from '../API/fetchmenu.js';
import axios from 'axios';
import Select from 'react-select';
const options = [
  { value: 'Single', label: 'Single' },
  { value: 'Variation', label: 'Variation' },
];
const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
const db = firebase.firestore();

class MednusEdit extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor(props) {
    super(props);
    this.state={ dataid:this.props.match.params.id,ingredient:[],menus:[],category:[],getIdeal:[],Alogo:[],selectedAlo:[],selectedAlo1:[],selectedmenu:[],selectedCat:[],selectedIdeal:[],selectedIng:[],selectedIdeal1:[],selectedIng1:[],
      imageVideoUrl:"",postType:"",name:"",price:"",des:"",con:"",rin:"",peso:"",selectedValue:"",downloadURL:"",image:"",videolink:"",type:'',inputList:[] }
    }
    componentDidMount= async ()=>{
      try{
        const ingredientss = await fetchmenu(this.state.dataid);
          var ing=[]
          for (var i = 0; i < ingredientss.Ingredient.length; i++) {
          ing[i] = {
          name:ingredientss.Ingredient[i],
          };
          }
          this.setState({selectedIng:ing});
         this.setState({selectedIng1:ingredientss.Ingredient,menusdata:ingredientss,
        name:ingredientss.dish_name,price:ingredientss.price,
        type:ingredientss.type ? ingredientss.type:'Single',inputList:ingredientss.variation ? ingredientss.variation : [{size:'',price:''}],  
        downloadURL:ingredientss.featured_image,imageVideoUrl:ingredientss.featured_image,des:ingredientss.description,selectedmenu:ingredientss.related,
        selectedCat:ingredientss.category,videolink:ingredientss.video ? ingredientss.video : "" });
      }
      catch(e)
    {
      console.log('ids');
    }
      try 
      {
         const ingredient = await getIngredient();
         this.setState({ingredient:ingredient.data});
      }
      catch(e)
      {
          console.log('first else');
      }
      try 
      {
        const category = await getcategory();
        this.setState({category:category});
      
      }
      catch(e)
      {
          console.log('first else');
      }
      try 
      {
         const ingredient = await getMenus();

         if(ingredient.list.length>0)
         {
         var title=[]
         for (var i = 0; i < ingredient.list.length; i++) {
         title[i] = {
         name:ingredient.list[i].dish_name,
         key:ingredient.ids[i]
         };
         }
         this.setState({menus:title});
         }


      }
      catch(e)
      {
          console.log('first else');
      }
    }


  onSelectmenu(selectedList, selectedItem) {
    this.setState({selectedmenu:selectedList});
}
onRemovemenu(selectedList, removedItem) {
  this.setState({selectedmenu:selectedList});
}

    onSelectCat(selectedList, selectedItem) {
      this.setState({selectedCat:selectedList});
  }
  onRemoveCat(selectedList, removedItem) {
    this.setState({selectedCat:selectedList});
  }
  onSelectIng(selectedList, selectedItem) {
    var output=[];
    selectedList.forEach(function(value, key) {
        output.push(value.name); 
    });
    this.setState({selectedIng1:output});
}
onRemoveIng(selectedList, removedItem) {
  var output=[];
    selectedList.forEach(function(value, key) {
        output.push(value.name); 
    });
    this.setState({selectedIng1:output});
}
  getname(e)
  {
this.setState({name:e.target.value});
  }
  getprice(e)
  {
    this.setState({price:e.target.value});
  }
  fetchdes(e)
  {
    this.setState({des:e.target.value});
  }

  onImageVideoChange = e => {
    let file = e.target.files[0];  
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
    var streetaddress= reader.result.substr(0, reader.result.indexOf('/'));
    if(streetaddress=="data:image")
    {
      this.setState({  
        imageVideoUrl: reader.result
     });
     this.handleUpload(reader.result)
    }
 };}

 getType=(selectedOption)=>{
  this.setState({type:selectedOption.value})
}

handleAddClick = () => {

  this.setState(prevState => ({ 
    inputList: [...prevState.inputList, { size: "", price: "" }]
  }),()=>{
    console.log('inc',this.state.inputList);
  })
  // this.setState({inputList:[...this.state.inputList, { firstName: "", lastName: "" }]});
};

  // handle click event of the Remove button
  handleRemoveClick = index => {
    const list = [...this.state.inputList];
    list.splice(index, 1);
    this.setState({inputList:list});
  };
  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.inputList];
    if(name=='price'){
    if (rx_live.test(value)){
      list[index][name] = value;
      this.setState({inputList:list});
      }
    }else{
      list[index][name] = value;
      this.setState({inputList:list});
    }
  };

handleUpload =(data)=>{
var body={image:data};
axios.post('https://rapidmalta.com/APIS/admin/imageurltwo',body, {
  headers: {
  Accept: "application/json",
  "Content-Type": "application/json",
},
})
.then((res) => {
this.setState({downloadURL:res.data.image});
})
}
submitdata= async ()=>{

if(this.state.name=="" || this.state.type=="" || this.state.des==""|| this.state.selectedCat==[] || this.state.selectedIng==[])
{
alert('please fill all details');
}
else{
if(this.state.downloadURL=="")
{
  alert('please Select image');
}
else{
  var object={
    'name':this.state.name,
    'price':this.state.type=='Single' ? this.state.price : this.state.inputList[0].price,
    'image':this.state.downloadURL,
    'description':this.state.des,
    'related':this.state.selectedmenu,
    'category':this.state.selectedCat,
    'videolink':this.state.videolink,
    'ing':this.state.selectedIng1,
    'type':this.state.type,
    'variation':this.state.inputList
  }
  try{
    console.log('variation',object.variation);
      var datasubmited = await Updatemenudata(object,this.state.dataid);
      if(datasubmited)
      {
        alert("New dish Updated successfully");
        this.props.history.push('/app/Menus');
      }
  }
  catch(e)
  {

  }
}

}

}

videolinkfunction(e)
{
this.setState({videolink:e.target.value});
}
  render() {
    const {title} = this.state;
    return (
      <div>
        <h1>Edit Menu</h1>
        <Row>
          <Col md="12">
                <div>
                  <Row>
                  <Col className="pl-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Select Categories
                        </label>
                        <Multiselect
                        class="form-control"
options={this.state.category} // Options to display in the dropdown
selectedValues={this.state.selectedCat} // Preselected value to persist in dropdown
onSelect={this.onSelectCat.bind(this)} // Function will trigger on select event
onRemove={this.onRemoveCat.bind(this)} // Function will trigger on remove event
displayValue="name" // Property name to display in the dropdown options
/>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Dish Name</label>
                        <Input
                        onChange={this.getname.bind(this)}
                        value={this.state.name}
                          placeholder="Dishname"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="px-1" md="6">
                      <FormGroup>
                        <label>Dish types</label>
                      <Select
                      class="form-control"
                      value={{'label':this.state.type,'value':this.state.type}}
                      onChange={this.getType}
                      options={options}
                      />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Select Related Dish</label>
                        <Multiselect
                        class="form-control"
options={this.state.menus} // Options to display in the dropdown
selectedValues={this.state.selectedmenu} // Preselected value to persist in dropdown
onSelect={this.onSelectmenu.bind(this)} // Function will trigger on select event
onRemove={this.onRemovemenu.bind(this)} // Function will trigger on remove event
displayValue="name" // Property name to display in the dropdown options
/>
                      </FormGroup>
                    </Col>
                 </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Select Ingredient</label>
                        <Multiselect
                        class="form-control"
options={this.state.ingredient} // Options to display in the dropdown
selectedValues={this.state.selectedIng} // Preselected value to persist in dropdown
onSelect={this.onSelectIng.bind(this)} // Function will trigger on select event
onRemove={this.onRemoveIng.bind(this)} // Function will trigger on remove event
displayValue="name" // Property name to display in the dropdown options
/>
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Tutorial link</label>
                        <Input
                          placeholder="Tutorial link"
                          type="text"
                          value={this.state.videolink}
                          onChange={this.videolinkfunction.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>Description</label>
                        <Input
                          cols="80"
                          placeholder="Description"
                          rows="4"
                          as="textarea"
                          value={this.state.des}
                          onChange={this.fetchdes.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {this.state.type=='Single' ? <Row>
                  <Col className="px-1" md="6">
                      <FormGroup>
                        <label>Price</label>
                        <Input
                          placeholder="Price"
                          type="Number"
                          value={this.state.price}
                          onChange={this.getprice.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>:""}
                  </Row>
              
                  
                  {this.state.type=='Variation' ? this.state.inputList.map((x, i) => {
        return (
          <Row className="mainrow">
          <Col className="px-1" md="5">
            <input
              name="size"
              class="form-control"
              placeholder="Enter Size"
              value={x.size}
              onChange={e => this.handleInputChange(e, i)}
            />
            </Col>
            <Col className="px-1" md="5">
            <input
              class="form-control"
              name="price"
              placeholder="Enter Price"
              value={x.price}
              onChange={e => this.handleInputChange(e, i)}
            />
            </Col>
            <Col className="px-1" md="2">
            <div className="btn-box">
              {this.state.inputList.length !== 1 && 
              <button
                className="btn removex"
                onClick={this.handleRemoveClick.bind(this,i)}>Remove</button>}
              {this.state.inputList.length - 1 === i && <button className="btn addx" onClick={this.handleAddClick.bind(this)}>Add</button>}
            </div>
            </Col>
          </Row>
        );
      }):""}
          
        

          <Row>
          <Col md="6">
                      <FormGroup>
                        <label>Dish Image</label>
                        <input
                            type="file" class="form-control" id="file" onChange={this.onImageVideoChange.bind(this)}  name="myfile"
                        />
                      </FormGroup>
                    </Col>
            {this.state.imageVideoUrl!="" ? 
          <Col md="6">
              <div className="">
                <img height={200}
                  alt="..."
                  src={this.state.imageVideoUrl}
                ></img>
              </div>
          </Col>:""}
          <Col md="12">
          <Button
                    className="btn-fill"
                    type="button"
                    variant="info"
                    onClick={this.submitdata.bind(this)}
                  >
                   Update Menue
                  </Button>
                  </Col>
          </Row>
                  
                  <div className="clearfix"></div>
                </div>
            
          </Col>
          
        </Row>
      </div>
    );
  }
}



export default withRouter(MednusEdit);
