import firebase from "firebase";
export default async function fetchmenu (id) {
               try {
                const documentSnapshot = await firebase.firestore().collection('menus')
                  .doc(id)
                  .get();
                const userData = await documentSnapshot.data();
               return await userData;
              } catch {
           return 'error';
              }
}