import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withRouter } from "react-router-dom";
import firebase from "firebase";
import Geocode from "react-geocode";
import Select from 'react-select';
const db = firebase.firestore();
const options = [
  { value: 'Own', label: 'Own' },
  { value: 'Ufood', label: 'Ufood' }
];
class Changepassword extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor(props) {
    super(props);

    this.state = {
      passo:"",
      passn:"",
      loader: false
    };
  }


  changepassword()
  {
     if(this.state.passn=="" || this.state.passn.length<5 && this.state.passo=="" || this.state.passo.length<5)
     {
        this.setState({error:"Please enter minimum 6 digit of new passwords"},()=>
        {
          alert("Please enter minimum 6 digit of new passwords");
          
        })
      
     }
     else{
        this.setState({loader:true});
        this.reauthenticate(this.state.passo).then(() => {
          var user = firebase.auth().currentUser;
          user.updatePassword(this.state.passn).then(() => {
           this.setState({loader:false,passo:"",passn:"",error:"Password update successfully"},()=>
           {
              alert('Password update successfully')
           })
          }).catch((error) => { 
           this.setState({loader:false,error:error.message},()=>
           {
              alert(error.message);
           })
           });
        }).catch((error) => {   this.setState({loader:false,error:error.message},()=>
        {
          alert(error.message);
        })});
     } 
  }
  reauthenticate = (passo) => {
     var user = firebase.auth().currentUser;
     var cred = firebase.auth.EmailAuthProvider.credential(
         localStorage.getItem('emailr'), passo);
     return user.reauthenticateWithCredential(cred);
   }
  firstpass(e)
  {
      this.setState({passo:e.target.value});
  }
  secondpass(e)
  {
      this.setState({passn:e.target.value});
  }
   



  render() {
    return (
      <div>
        <h1>Change Password</h1>
        <Row>
          <Col sm={12}>
              <div>
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="info">
                    {this.props.message}
                  </Alert>
                )}
               <FormGroup>
                  <Label for="input-title">Current Password</Label>
                  <Input
                    id="input-title"
                    type="text"
                    placeholder="Current Password"
                    value={this.state.passo} 
                    required
                    onChange={this.firstpass.bind(this)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-title">New Password</Label>
                  <Input
                    id="input-title"
                    type="text"
                    placeholder="New Password"
                    value={this.state.passn}
                    required
                    onChange={this.secondpass.bind(this)}
                  />
                </FormGroup>
               
                                    
               
                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="danger" type="button" onClick={this.changepassword.bind(this)} >
                      {this.state.loader==true ? 'Updateing...' : 'Change Password'}
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
          </Col>
        </Row>
      </div>
    );
  }
}



export default withRouter(Changepassword);
