import firebase from "firebase";
export default async function getIngredient () {
          var response =  await firebase.firestore().collection("ingredients").where('uid', '==', localStorage.getItem("uidr")).get();
            const data = await response.docs.map(doc => doc.data());
            const data2 = await response.docs.map(doc => doc.id);
               if(data!='')
               {
                   var object={'data':data,'ids':data2};
                   return await object;
               }
               else
               {
                   return "error"
               }
                           
}