import firebase from "firebase";
export default async function Updatemenudata(object,id) {
  try{
   
    const userRef = await firebase.firestore().collection('menus').doc(id).update({
      dish_name: object.name,
      price:object.price,
      resturent_name:"dummyname",
      featured_image:object.image,
      description:object.description,
      category:object.category,
      video:object.videolink,
      related:object.related,
      Ingredient:object.ing,
      type:object.type,
      variation:object.variation
    });
   var data = await userRef.docs.map(doc => doc.data());
    return data;
  }
  catch(e){
    return "error"
  }
}