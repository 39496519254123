import React from 'react';
import { Switch, Route, withRouter } from 'react-router';

import IngredientsList from './list/IngredientsList';
import IngredientsNew from './new/IngredientsNew';
import IngredientsEdit from './edit/IngredientsEdit';

class Ingredients extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/Ingredients" exact component={IngredientsList} />
        <Route path="/app/Ingredients/new" exact component={IngredientsNew} />
        <Route path="/app/Ingredients/edit/:id" exact component={IngredientsEdit} />
      </Switch>
    );
  }
}

export default withRouter(Ingredients);
